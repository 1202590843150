<template>
 <div class="DomainsList">
   <div class="card-items">
     <Loader v-if="isCartLoading" />
     <md-card v-else class="cart-item" v-for="item in entries" :key="item.id">
       <div class="card-content">
         <div class="md-gutter">
           <div class="left-content">
             <h3 class="o-default-h3">{{ item.productName }}</h3>
             <h5 class="o-default-h5"> {{ $t('cartItems.text' + Helpers.capitalizeEachWord(item.productInfo)) }}</h5>
             <div class="u-font-body-regular">
               {{ $t('cartItems.period') }}{{ ':' }}
               <span>{{ item.productQuantity }} {{ $tc('cartItems.years', item.productQuantity) }}</span>
             </div>
             <div class="card-caption u-font-body-regular">
               {{ $t('cartItems.text' + Helpers.capitalizeEachWord(item.productInfo)) }}
               {{ $t('cartItems.for') }} {{ $t('cartItems.euroSign') }}{{ item.priceEuroNoVat.toFixed(2) }}/{{ $tc('cartItems.years', 1) }}
             </div>
           </div> <!-- /.left-content -->
         </div> <!-- /.md-gutter -->
         <div class="cart-price-green">
           {{ $t('cartItems.euroSign') }}{{ item.totalEntryPriceNoVatEuro.toFixed(2) }}
         </div>
       </div>
       <md-divider />
     <!-- Uncomment this section when it will be implemented -->
     <!-- <section class="cart-actions">
         <md-card-content>
           <div class="md-layout md-gutter">
             <div class="discount-code md-layout-item">
               <md-button>
                 <span class="md-caption u-text-underline">{{ $t('cartItems.voucher') }}</span>
               </md-button>
             </div> /.discount-code
             <div class="empty-cart md-layout-item">
               <md-button>
                 <md-icon class="md-8">delete</md-icon>
                 <span class="md-caption u-text-underline">{{ $t('cartItems.emptyCart') }}</span>
               </md-button>
             </div> /.empty-cart
           </div> /.md-layout
         </md-card-content>
       </section> -->
     </md-card>
   </div> <!-- /.card-items -->
 </div> <!-- /.DomainsList -->
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/util/Loader';

export default {
 name: 'DomainsList',
 components: {
   Loader
 },
 data() {
   return {
     items: [],
     totalPrice: 0,
     totalPriceNoVat: 0,
     vat: 0,
   };
 },
 computed: {
   ...mapGetters([
     'entries',
     'isCartLoading',
   ])
 },
};
</script>

<style lang="scss" scoped>
.DomainsList {
 .card-content {
   display: flex;
   justify-content: space-between;
   padding: 1.4rem 2.4rem;
   text-align: left;
 }
 .card-caption {
   min-width: 10rem;
 }
 .card-items {
   background-color: $white;
   box-shadow: none;
   flex: 1 1 100%;
   height: 100%;
   margin: 0 auto;
   overflow-x: hidden;
   overflow: visible;
 }

 .cart-item {
   box-shadow: transparent;
   display: flex;
   flex-direction: column;
   height: 100%;
   overflow: visible;
   padding: 2.6rem auto;
 }

 .cart-actions {
   .discount-code {
     text-align: center;
     @include desktop {
       text-align: left;
     }
   }
   .empty-cart {
     text-align: center;
     @include desktop {
       text-align: right;
     }
   }
 }

 .cart-price-green {
   color: $palette-primary-color-two;
   font-size: 2.2rem;
   font-weight: 600;
   margin-top: 1rem;
   text-align: right;
   text-indent: .2rem;
   @include tablet {
     margin-top: 10rem;
   }
 }

 .left-content {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   gap: .6rem;
 }
}
</style>
